body {
   
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',"Cairo"
    sans-serif;
    font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace,"Cairo";
}
.bg {
  background-image: url('../src//theme//images/mainbk.png');
  background-repeat: no-repeat;
  background-position:top;
  position: relative;
  width: 100%;
  padding-bottom: 30%;
  background-position-x:right;
  background-size:auto;
  object-fit: fill;
     
}
.bg2 {
  background-image: url('../src//theme//images/mainbk.png');
  background-repeat: no-repeat;
  background-position: center;
  background-position-x:right;
  background-size: contain;
  
     
}