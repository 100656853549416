 
.App {
  text-align: center;
  min-height: 100%;
  max-width: 800px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#roo{
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-app{
  max-width:100px;
  height: 100vh;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.warper-component{
  align-content: center;
  margin-top: 1;

}
 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.imagepos {
position: relative;
top: 1;
}
.user-regestr {
  background-color:violet;
  
}
.img-pos{
  position: relative;
  top: 12px;
  

 
}

.g-btn {
  width: "100%";
  margin-top: 10px;
  margin-left: 10px;
}
.image-slider-container {
  margin: 2 auto;
  width: 400px;
}
.form-login{
  align-items: center;
  flex-direction: column;
}
.auth-code{
  flex-direction: row;
}

.image-slider-container .slick-prev:before,
.image-slider-container .slick-next:before {
  color: black;
  
}

.App-foter {
  background-color:forestgreen;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.Box-align{
  justify-content: center ;
  margin-top: 10px;
  background-color: darkorchid;
  border-width: 1;
}
 
.loading-icon {
  color: teal;
  font-size: 2rem;
  animation: animate 2s infinite;
  margin-bottom: 4px;
}
 
@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(720deg);
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family:Cairo;
  src: url(../src/theme/fonts/static/Cairo-Regular.ttf);
}
.Cairo{
  font-family: Cairo;
}